html {
  font-family: "Cormorant", serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.01em;
  font-style: normal;
  color: black;
  --accent-color: #805ad5;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

*:focus {
  outline-color: purple;
}

h1 {
  font-size: 1rem;
  text-align: center;
}

.top {
  margin: 80px 15vw 40px;
  height: 2.6666666667rem;
}

.card {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  perspective: 1000px;
  transition: left 300ms ease-in;
  transition-delay: 100ms;
  left: 0px;
}

.card_movingout {
  left: 100%;
}

.card.card_open .card__flipper {
  transform: rotateY(180deg);
}

.card__flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.card,
.card__front,
.card__back {
  box-sizing: border-box;
  width: 60vw;
  height: 80vw;
  max-width: 15rem;
  max-height: calc(15rem * 1.33333333);
  cursor: pointer;
}

.card__front {
  transition-property: box-shadow, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
  transform: translateZ(0);
  border-radius: 7px;
  box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0);
  background-image: url(./pattern.svg);
  background-size: calc(min(15rem/6, 10vw));
  z-index: 2;
  transform: rotateY(0deg);
}

.card__back {
  border-radius: 7px;
  box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.15);
  padding: 30px;
  text-align: left;
  background: white;
  color: black;
  transform: rotateY(180deg);
}

.card__flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.card__front,
.card__back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.card__title {
  color: var(--accent-color);
  padding-bottom: 20px;
}

.card__front:hover {
  box-shadow: 0px 6px 8px 6px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
}

.topics {
  display: block;
  position: relative;
  margin-bottom: 40px;
  left: 100%;
  transition: left 300ms ease-in;
}

.topics.topics_open {
  left: 0px;
}

.topics__card {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 60vw;
  max-width: 15rem;
  border-radius: 7px;
  box-shadow: 0px 4px 6px 4px rgba(0, 0, 0, 0.15);
  padding: 30px;
  text-align: left;
  background: white;
  color: black;
  margin-top: -8px;
}

.topics__card:last-child {
  height: 80vw;
  max-height: calc(15rem * 1.4166666667);
}


@media only screen and (min-width: 660px) {
  .topics {
    display: grid;
    max-width: 1280px;
    grid-template-columns: repeat(auto-fill, 15rem);

    margin: 0 auto;
    grid-gap: 20px;
    justify-content: center;
  }
  
  .topics__card {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  
  .topics__card:last-child {
    height: auto;
  }
}

.topics__title {
  color: var(--accent-color);
  padding-bottom: 20px;
}

.btn {
  transition-property: box-shadow, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
  display: block;
  padding: 8px 35px;
  margin: 20px auto;
  background: var(--accent-color);
  border-width: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  transform: translateZ(0);
  cursor: pointer;
  width: 12em;
}


.btn:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  transform: scale(1.05);
}

.btn:active {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  transform: scale(1);
}

.checkbox-contain *,
.checkbox-contain *::before,
.checkbox-contain *::after {
  box-sizing: content-box !important;
}

.checkbox-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-contain span {
  line-height: 1;
  font-size: 1rem;
  font-family: inherit;
}

.checkbox-contain {
  display: table;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  margin-bottom: 0.4rem;
}

.checkbox-contain input[type="checkbox"] ~ .checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: white;
  transition: background 250ms;
  border: 1px solid #a88aeb;
  border-radius: 0.2rem;
}

.checkbox-contain input[type="checkbox"] ~ .checkbox-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 0.3rem;
  height: 0.6rem;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.checkbox-contain input[type="checkbox"]:disabled ~ .checkbox-input::after {
  border-color: #ffffff;
}

.checkbox-contain input:checked ~ .checkbox-input::after {
  display: block;
}

.checkbox-contain:hover
  input[type="checkbox"]:not([disabled])
  ~ .checkbox-input,
.checkbox-contain input[type="checkbox"]:focus ~ .checkbox-input {
  background: white;
  border-color: #a88aeb;
}

.checkbox-contain input:focus ~ .checkbox-input {
  box-shadow: 0 0 0 2px #60a5fa;
}

.checkbox-contain input[type="checkbox"]:checked ~ .checkbox-input {
  background: #805ad5;
  border-color: #805ad5;
}

.checkbox-contain input[type="checkbox"]:disabled ~ .checkbox-input {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox-contain:hover
  input[type="checkbox"]:not([disabled]):checked
  ~ .checkbox-input,
.checkbox-contain input[type="checkbox"]:checked:focus ~ .checkbox-input {
  background: #a88aeb;
  border-color: #a88aeb;
}

a {
  color: var(--accent-color);
}

.footer {
  margin: 40px 15vw 40px;
  text-align: center;
}
